<div class="page">
  <section class="min-h-[50dvh] md:min-h-[50vh] w-full p-3 md:py-10 md:px-16" aria-labelledby="checkout-title">
    <h2 id="checkout-title" class="text-4xl md:text-6xl font-bold pb-6 md:pb-12">Checkout</h2>
    @if (isLoading()) {
    <div class="flex items-center gap-3">
      <app-spinner [size]="24"></app-spinner>
      <span>Getting basket...</span>
    </div>
    } @else {
    @if (basket(); as basket) {
    <div class="flex flex-col md:flex-row gap-6">
      <div class="flex-1 flex flex-col gap-4">
        <app-reserved-tickets [tickets]="basket.tickets"></app-reserved-tickets>
        <div>
          <button class="outline-button border-white text-white" (click)="cancel()">Cancel my order</button>
        </div>
      </div>

      <div class="flex-1 flex flex-col gap-6">
        @if (basket) {
        <app-reservation-timer [start]="start()" [end]="end()"></app-reservation-timer>

        <app-payment-options [basket]="basket"></app-payment-options>
        }
      </div>
    </div>
    } @else {
    <div class="flex flex-col md:flex-row gap-6 md:gap-[8%]">
      <div class="md:w-1/2">
        <section class="w-full px-2 py-4 flex flex-col justify-start bg-neutral-800 p-2 md:p-10">
          <h3 id="basket-title" class="text-2xl font-bold pb-3 md:pb-6">Basket</h3>
          <div class="flex items-center">Currently you don't have any tickets in your basket.</div>
        </section>
      </div>
    </div>
    }
    }
  </section>
</div>