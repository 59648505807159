import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, OnDestroy, signal } from '@angular/core';
import { EMPTY, finalize, from, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { SpinnerComponent } from '@components/spinner';

import {
  OutOfTimeDialogComponent,
  PaymentOptionsComponent,
  ReservationTimerComponent,
  ReservedTicketsComponent,
} from '../../components';
import { BasketService } from '../../services';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog';

@Component({
  selector: 'app-checkout-page',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ReservedTicketsComponent,
    ReservationTimerComponent,
    PaymentOptionsComponent,
  ],
  templateUrl: './checkout-page.component.html',
  styleUrl: './checkout-page.component.scss',
})
export class CheckoutPageComponent implements OnDestroy {
  #basketService = inject(BasketService);
  #router = inject(Router);
  #dialog = inject(Dialog);
  #timeout?: number;

  protected basket = toSignal(
    this.#basketService.getBasket().pipe(
      finalize(() => {
        this.isLoading.set(false);
      }),
    ),
  );

  protected isLoading = signal<boolean>(true);

  protected start = computed(() => {
    return this.basket()?.ticket_hold_start_time ? new Date(this.basket()!.ticket_hold_start_time).getTime() : 0;
  });

  protected end = computed(() => {
    return this.basket()?.ticket_hold_end_time ? new Date(this.basket()!.ticket_hold_end_time).getTime() : 0;
  });

  constructor() {
    effect(() => {
      const basket = this.basket();

      if (basket?.tickets.length) {
        clearTimeout(this.#timeout);

        this.#timeout = window.setTimeout(
          () => this.showOutOfTimeDialog(),
          Math.max(0, this.end() - new Date().getTime()),
        );
      }
    });
  }

  protected cancel() {
    this.#dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Are you sure?',
          description: 'Are you sure you want to cancel your reservation?',
          confirmButton: 'Yes, cancel',
          cancelButton: 'No',
        },
      })
      .closed.pipe(
        switchMap((result) =>
          result
            ? this.#basketService.cancel().pipe(switchMap(() => from(this.#router.navigate(['/', 'events']))))
            : EMPTY,
        ),
      )
      .subscribe();
  }

  private showOutOfTimeDialog() {
    this.#dialog.open(OutOfTimeDialogComponent, { disableClose: true }).closed.subscribe(() => {
      this.#router.navigate(['/', 'events']);
    });
  }

  ngOnDestroy() {
    clearTimeout(this.#timeout);
  }
}
