import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '@features/api';
import { Ticket } from '@features/events';

import {
  AddToBasketRequest,
  AddToBasketRequestTicket,
  Basket,
  PaymentIntent,
  ValidateCheckoutResponse,
} from '../models';

@Injectable({ providedIn: 'root' })
export class BasketService {
  #api = inject(ApiService);

  addTickets(tickets: Ticket[]) {
    const payload = this.getAddToBasketPayload(tickets);
    return this.#api.post<Basket>('/basket', payload);
  }

  getBasket(): Observable<Basket | null> {
    return this.#api.get<{ basket: Basket | null }>('/basket').pipe(map(({ basket }) => basket));
  }

  validateCheckout() {
    return this.#api.get<ValidateCheckoutResponse>('/basket/checkout/validate');
  }

  getPaymentIntent() {
    return this.#api.get<PaymentIntent>('/basket/checkout');
  }

  cancel() {
    return this.#api.delete('/basket');
  }

  private getAddToBasketPayload(tickets: Ticket[]): AddToBasketRequest {
    const payload = tickets.reduce((acc: AddToBasketRequestTicket[], ticket): AddToBasketRequestTicket[] => {
      const options = new Map();
      for (const option of ticket.options) {
        const counter = options.get(option()) ?? 0;
        options.set(option(), counter + 1);
      }

      const mappedTickets: AddToBasketRequestTicket[] = [];
      for (const [ticket_option, quantity] of options.entries()) {
        mappedTickets.push({
          ticket_type_id: ticket.id,
          quantity,
          ticket_option,
        });
      }

      return [...acc, ...mappedTickets];
    }, []);

    return { tickets: payload };
  }
}
