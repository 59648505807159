import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, from, switchMap } from 'rxjs';

import { SpinnerComponent } from '@components/spinner';

import { BasketService } from '../../services';

@Component({
  selector: 'app-checkout-return-page',
  templateUrl: './checkout-return-page.component.html',
  styleUrls: ['./checkout-return-page.component.scss'],
  standalone: true,
  imports: [SpinnerComponent],
})
export class CheckoutReturnPageComponent implements OnInit {
  #basket = inject(BasketService);
  #router = inject(Router);
  #destroyed = inject(DestroyRef);

  ngOnInit(): void {
    this.#basket
      .validateCheckout()
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        switchMap(({ order }) => from(this.#router.navigate(['/checkout', 'complete', order.id]))),
        catchError(() => from(this.#router.navigate(['/']))),
      )
      .subscribe();
  }
}
