import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { interval, map } from 'rxjs';

@Component({
  selector: 'app-reservation-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './reservation-timer.component.html',
  styleUrl: './reservation-timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationTimerComponent {
  start = input.required<number>();
  end = input.required<number>();

  duration = computed(() => {
    return Math.max((this.end() - this.start()) / 1000, 0);
  });

  remaining = toSignal(
    interval(1000).pipe(map(() => Math.max(0, this.duration() - (new Date().getTime() - this.start()) / 1000))),
    {
      initialValue: 0,
    },
  );

  width = computed(() => (this.remaining() / this.duration()) * 100);
}
