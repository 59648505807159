@if(loaded()) {

@if (booking(); as booking) {
<div class="page">
  <section class="w-full px-2 py-4 md:px-24 md:py-12 flex flex-col md:grid md:grid-cols-2 gap-5"
    aria-label="Checkout complete">
    <div>
      <h1 class="text-5xl md:text-6xl font-bold">You're going to<br />Bongo's Bingo!</h1>
    </div>

    <div class="pt-6 md:pt-0" role="contentinfo">
      <h2 class="text-xl md:text-3xl font-bold">Order Confirmation Number</h2>
      <p class="text-lg">{{ booking.id }}</p>
      <p class="pt-5 md:pt-8">
        You should have received an email confirming your purchase including a breakdown of your payment along with
        any additional information. If you have any issues, please contact us via the contact page.
      </p>
    </div>

    <div class="flex flex-col md:flex-row flex-wrap gap-2 md:gap-x-7 pt-6 md:pt-12">
      <button class="grow md:min-w-48 outline-button secondary md:text-xs uppercase">What you need to know</button>
    </div>
    <div class="hidden md:inline-block"></div>

    <div class="col-span-2">
      <app-assign-tickets [booking]="booking"></app-assign-tickets>
    </div>
  </section>
</div>
}} @else {
<div class="min-h-screen flex flex-col items-center justify-center">
  <app-spinner></app-spinner>
</div>
}