import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';

import { AssignTicketsComponent, Booking, BookingsService } from '@features/bookings';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';

@Component({
  selector: 'app-checkout-complete-page',
  standalone: true,
  imports: [CommonModule, AssignTicketsComponent, SpinnerComponent],
  templateUrl: './checkout-complete-page.component.html',
  styleUrl: './checkout-complete-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutCompletePageComponent implements OnInit {
  #bookings = inject(BookingsService);
  #router = inject(Router);
  #destroyed = inject(DestroyRef);

  protected loaded = signal(false);

  protected orderId = input.required<string>();

  protected booking = signal<Booking | null>(null);

  ngOnInit(): void {
    this.#bookings
      .getBooking(this.orderId())
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        finalize(() => this.loaded.set(true)),
      )
      .subscribe({
        next: (booking) => this.booking.set(booking),
        error: () => this.#router.navigateByUrl('/'),
      });
  }
}
