<div class="w-full bg-neutral-800 transition-all duration-700">
  <section class="p-3 md:px-6 md:py-4 flex gap-1 items-center justify-start text-xl md:text-2xl font-bold"
    aria-labelledby="reservation-timer-title">
    <h2 id="reservation-timer-title" class="flex-grow">Your tickets are reserved for:</h2>
    <h3 role="timer">{{ remaining() * 1000 | date: 'm:ss' }}</h3>
  </section>

  @if (remaining() >= 0) {
  <div class="h-2 bg-gradient-to-b from-[#ee4f9c] to-[#cc00ffc6]" [style.width.%]="width()">
  </div>
  }
</div>